import axios from "axios";
import { API_URL2 } from "../url/URL";


const NaverWithGoogle = async () => {
	await axios
		.get(`${API_URL2}user/naver/login`)
		.then((res) => {
			const url = res.data;
			window.location.href = url;
		})
		.catch((err) => {});
};

export default NaverWithGoogle;
