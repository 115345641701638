import { useState } from "react";
import { login } from "./LoginFormRepo";
import { setCookie } from "@/services/cookies/UserCookies";
import MyPageGet from "../mypage/MyPageGet";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LicenseExamination } from "../mypage/LicenseExamination";
import { DashBoard } from "../workspace/DashBoard";
import { setDashboardData } from "@/services/store/action/DashBoard";
import { DataCount } from "../mypage/MyDataCount";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { CookiesMaxAge, emailRegex } from "@/services/util/const";
import { alertFormatDate } from "@/services/util/TransForm";
import { loginSuccess } from "@/services/store/action/LoginAction";

export const useLoginForm = (emailInputRef, passwordInputRef, to = "/dashboard") => {
	const [userEmail, setUserEmail] = useState("");
	const [userPw, setUserPw] = useState("");
	const [error, setError] = useState(null);
	const [emailTrim, setEmailTrim] = useState(true);
	const [pwTrim, setPwTrim] = useState(true);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleLogin = async (event) => {
		event.preventDefault();

		const trimmedEmail = userEmail.trim();
		const trimmedPw = userPw.trim();

		setEmailTrim(!!trimmedEmail);
		setPwTrim(!!trimmedPw);

		// 두 필드가 모두 비어있는 경우
		if (!trimmedEmail && !trimmedPw) {
			setError("이메일과 비밀번호를 입력해주세요.");
			emailInputRef.current.focus();
			return;
		}

		// 이메일 필드만 비어있는 경우
		if (!trimmedEmail) {
			setError("이메일을 입력해주세요.");
			emailInputRef.current.focus();
			return;
		}

		// 비밀번호 필드만 비어있는 경우
		if (!trimmedPw) {
			setError("비밀번호를 입력해주세요.");
			passwordInputRef.current.focus();
			return;
		}

		// 이메일 형식이 잘못된 경우
		if (!emailRegex.test(trimmedEmail)) {
			setError("올바른 이메일 형식을 입력해주세요.");
			emailInputRef.current.focus();
			return;
		}

		// 유효성 검증 통과 후 로그인 처리
		try {
			const data = await login(userEmail, userPw);
			if (data.data.access_token) {
				setCookie("token", data.data.access_token, { path: "/", maxAge: CookiesMaxAge });
				await MyPageGet(dispatch);
				await LicenseExamination(dispatch);

				// 로그인 성공 시 사용자 데이터를 Redux에 설정
				dispatch(loginSuccess(data.data.user));

				const dataCountResult = await DataCount();
				dispatch(setDataCount(dataCountResult));
				const dashboardData = await DashBoard(null, 10, "", null, 1, true);
				dispatch(setDashboardData(dashboardData));

				navigate(to); // 유효성 검증 통과 시에만 리다이렉트
			} else {
				console.error("로그인 처리 오류", error);
			}
		} catch (error) {
			// 에러 메시지를 상태에 저장
			if (error.response && error.response.status === 400) {
				emailInputRef.current.focus();
				setError("이메일 또는 비밀번호가 올바르지 않습니다. 입력한 내용을 다시 확인해 주세요.");
				if (error.response.data.message.msg === "탈퇴한 회원입니다.") {
					const dateString = error.response.data.message.date;
					const formattedDate = alertFormatDate(dateString);
					alert({ title: `[탈퇴] 탈퇴한 사용자입니다.`, message: `${formattedDate} 이후에 가입이 가능합니다.` });
				}

				if (error.response.data.message === "차단한 회원입니다.") {
					alert({ message: `[블럭] 계정이 잠겼습니다. 운영팀에 문의하세요.` });
				}
			}
			if (error.response && error.response.status === 422) {
				emailInputRef.current.focus();
				setError("이메일 또는 비밀번호가 올바르지 않습니다. 입력한 내용을 다시 확인해 주세요.");
			}
		}
	};

	return {
		userEmail,
		setUserEmail,
		userPw,
		setUserPw,
		error,
		handleLogin,
		emailTrim,
		pwTrim
	};
};
