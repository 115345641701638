import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Dropzone from "@/components/form/DragAndDrop";
import Button from "@/components/button/Button";
import api, { refreshCancelTokenSource } from "@/services/util/Interceptor";

// import axios, { all } from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { brightnessReducer } from "@/services/store/reducer/BrightnessReducer";
import {
	resetImageOutput,
	setBrightnessData,
	setBrightnessStep,
	setImageData
} from "@/services/store/action/BrightnessAction";
import { setUserData } from "@/services/store/action/LoginAction";
import { IMGBRIGHTNESS_ADD } from "@/services/core/url/URL_Brigthness";
import { DataCount } from "@/services/core/mypage/MyDataCount";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
// import { DashBoard } from "@/services/core/workspace/DashBoard";
// import { setDashboardData } from "@/services/store/action/DashBoard";

export default function ContrastAddPage() {
	const navigate = useNavigate();
	const [selectedConformity, setSelectedConformity] = useState({ level: "AA", brightness: 3 });
	const [isFileUploaded, setIsFileUploaded] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();
	const useDataCount = useSelector((state) => state.user.userData.data.count);
	const userData = useSelector((state) => state.user.userData);
	const location = useLocation();
	const [isInitialUpload, setIsInitialUpload] = useState(true);

	const handleContrastCheck = async () => {
		// [개발] 로딩을 시뮬레이션하기 위한 지연시간입니다. => 수정 및 변경 필요
		// [개발] 사용권 사용컴펌 팝업 노출

		if (useDataCount > 0) {
			const isConfirmed = window.confirm({
				title: "이미지 명도대비를 진단하시겠어요?",
				content: `사용권 1장이 차감됩니다.`,
				confirmButtonText: "진단",
				cancelButtonText: "취소",
				onConfirm: async () => {
					setIsLoading(true);

					const formData = new FormData();

					if (uploadedFile) {
						formData.append("file", uploadedFile);
					}
					const imgType = uploadedFile.type;
					const extension = imgType.split("/").pop();
					try {
						const resp = await api.post(IMGBRIGHTNESS_ADD, formData, {
							headers: {
								"Content-Type": "multipart/form-data"
							},
							params: {
								ratio: selectedConformity.brightness,
								extension: extension
							}
						});

						dispatch(setImageData(resp.data));
						const updatedUserData = {
							...userData,
							data: {
								...userData.data,
								count: userData.data.count - 1
							}
						};

						dispatch(setBrightnessData(selectedConformity.brightness));
						dispatch(setBrightnessStep(1));
						dispatch(resetImageOutput());

						dispatch(setUserData(updatedUserData));
						const dataCountResult = await DataCount();
						dispatch(setDataCount(dataCountResult));

						const workId = resp.data.data.workspaceId;
						setIsLoading(false);
						navigate(`/tool/contrast/work-detail/${encodeURIComponent(workId)}`);
					} catch (error) {
						if (error.code == "ERR_CANCELED") {
						} else {
							console.error("[ERROR]명도대비 생성", error);

							window.alert({
								title: "오류가 발생하였습니다.",
								message: "다시 시도해주세요."
							});
						}

						setIsLoading(false);
					}
				}
			});
		} else {
			window.confirm({
				title: "사용권이 없어요.",
				content: "새로운 사용권을 구매하거나 기간이 유효한 사용권코드를 입력해주세요.",
				confirmButtonText: "사용권 구매하기",
				cancelButtonText: "취소",
				onConfirm: () => handleBuyUsage()
			});
			setIsLoading(false);
		}
	};
	const handleBuyUsage = () => {
		setIsLoading(false);
		navigate("/setting/license");
	};
	const [uploadedFile, setUploadedFile] = useState(null);

	const handleConformityChange = (value) => {
		const brightness = value === "AAA" ? 4.5 : 3;
		setSelectedConformity({ level: value, brightness });
	};

	const onFileUploaded = (file) => {
		setUploadedFile(file);
		setIsFileUploaded(true);
	};

	const onFileRemoved = () => {
		setIsFileUploaded(false);
	};

	useEffect(() => {
		refreshCancelTokenSource();
		return () => {
			refreshCancelTokenSource();
		};
	}, [location]);

	useEffect(() => {
		if (isInitialUpload && uploadedFile) {
			setIsInitialUpload(false);
		}
	}, [uploadedFile]);

	return (
		<>
			<div className="container contrast-page">
				<div className="content-box content-tooladd">
					<div className="tooladd-wrap">
						<div className="tooladd-header">
							<h2 className="page-title">
								<i className="ico ico-service-contrast-lg"></i>
								<span>이미지 명도대비 최적화</span>
							</h2>
						</div>
						<div className="tooladd-body">
							<div className="inner">
								<div className="box">
									<div className="info-area">
										<strong>인식의 용이성 (5.3.3. 명도 대비)</strong>
										<ul className="list list1 mt-2">
											<li>저시력자와 고령자는 작거나 흐릿한 글자를 잘 보지 못해 자주 어려움을 겪어요.</li>
											<li>
												포스터, 카드뉴스, 배너, 쇼핑몰 상세 이미지 글자 명도대비를 진단하고 최적의 색상으로
												개선해보세요.
											</li>
										</ul>
									</div>
								</div>
								<p className="text-guide mt-8">이미지 사용 환경을 고려해 진단 기준을 선택해주세요.</p>
								<ul className="conformity-level">
									<li>
										<span className="check-item">
											<input
												type="radio"
												id="contrastAa"
												name="contrastRadio"
												checked={selectedConformity.level === "AA"}
												onChange={() => handleConformityChange("AA")}
											/>
											<label htmlFor="contrastAa">
												<strong className="level">A</strong>
												<strong className="contrast">3:1</strong>
												<span className="badge hot">인기</span>
												<p className="label">확대되는 화면에서 더 다채로운 색상 제안</p>
											</label>
										</span>
									</li>
									<li>
										<span className="check-item">
											<input
												type="radio"
												id="contrastAaa"
												name="contrastRadio"
												checked={selectedConformity.level === "AAA"}
												onChange={() => handleConformityChange("AAA")}
											/>
											<label htmlFor="contrastAaa">
												<strong className="level">AA</strong>
												<strong className="contrast">4.5:1</strong>
												<span className="badge">권장</span>
												<p className="label">웹 접근성 지침에서 권장하는 명도대비</p>
											</label>
										</span>
									</li>
								</ul>

								<div className="tool-file-upload">
									<Dropzone
										showDeleteButton={true}
										hideGuideText={!isFileUploaded}
										onFileUploaded={onFileUploaded}
										onFileRemoved={onFileRemoved}
										service={"brightness"}
									/>
								</div>
							</div>
							<div className="btn-group btn-group-flex mt-8">
								<Button
									icon={{ classes: "ico-tool-md" }}
									text="명도대비 AI 진단하기"
									loadingText={true}
									isLoading={isLoading}
									size="xl"
									color="primary"
									onClick={handleContrastCheck}
									className={`btn btn-xl btn-primary ${!isFileUploaded ? "disabled" : ""}`}
									disabled={!isFileUploaded}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
