export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%^&#*?])[A-Za-z\d@$!%^&#*?]{8,16}$/;

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const nameRegex = /^[가-힣a-zA-Z]{1,20}$/;

export const SidebarSearchCount = 50;

export const isValidPhoneNumber = /^010[0-9]{7,8}$/;

export const SingUpTimerDefault = 300;

export const DebounceTime = 500;

export const ImgBrigthnessUrl = "https://xrai-dev.store";

// export const ImgBrigthnessUrl = "http://127.0.0.1:1280"

export const DefaultAutoSaveTime = 120000;

export const CookiesMaxAge = 86400;

export const defaultProfileImage = "https://cdn.jsdelivr.net/gh/inseq/a11y-output@v0.0.1/images/ico-profile-sm@2x.png";

export const ImgPath = "https://xrai-dev.store";


// export const rootUrl = "https://www.a11y.co.kr:9991"

/// 디폴트 이미지 용량, 가로, 높이 <= 프로필에 사용중
export const ImgDefaultMB = 0.5;
export const ImgDefaultWidth = 2000;
export const ImgDefaultHeight = 5000;

/// 이미지 HTML 변환기 용량, 가로, 높이

export const Img2MB = 20;
export const Img2Width = 5000;
export const Img2Height = 10000;

/// 표 접근성 최적화 용량, 가로 , 높이

export const BrightnessMB = 20;
export const BrightnessWidth = 5000;
export const BrightnessHeight = 10000;

