import axios from "axios";
import { API_URL2 } from "../url/URL";
const LoginWithGoogle = async () => {
	await axios
		.get(`${API_URL2}user/google/login`)
		.then((res) => {
			const url = res.data;
			window.location.href = url;
		})
		.catch((err) => {
			console.error(err);
		});
};

export default LoginWithGoogle;
