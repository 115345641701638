import {
	BrightnessHeight,
	BrightnessMB,
	BrightnessWidth,
	Img2Height,
	Img2MB,
	Img2Width,
	ImgDefaultHeight,
	ImgDefaultMB,
	ImgDefaultWidth,
	SeoHeight,
	SeoMB,
	SeoWidth
} from "@/services/util/const";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = ({ showDeleteButton, hideGuideText, onFileUploaded, onFileRemoved, service }) => {
	const [acceptedFiles, setAcceptedFiles] = useState([]);
	const [showGuideText, setShowGuideText] = useState(true);

	const config = {
		default: {
			maxSizeInMB: ImgDefaultMB,
			acceptedFormats: [".jpg", ".jpeg", ".png", ".gif", ".bmp"],
			maxDimensions: { width: ImgDefaultWidth, height: ImgDefaultHeight }
		},
		IMG2: {
			maxSizeInMB: Img2MB,
			acceptedFormats: [".jpg", ".jpeg", ".png", ".gif", ".bmp"],
			maxDimensions: { width: Img2Width, height: Img2Height }
		},
		brightness: {
			maxSizeInMB: BrightnessMB,
			acceptedFormats: [".jpg", ".jpeg", ".png", ".gif", ".bmp"],
			maxDimensions: { width: BrightnessWidth, height: BrightnessHeight }
		}
	};

	const { maxSizeInMB, acceptedFormats, maxDimensions } = config[service] || config["default"];

	useEffect(() => {}, [acceptedFiles]);

	const handlePaste = useCallback((event) => {
		const items = event.clipboardData.items;
		for (let i = 0; i < items.length; i++) {
			if (items[i].type.indexOf("image") !== -1) {
				const blob = items[i].getAsFile();
				handleFile(blob);
				event.preventDefault();
				break;
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener("paste", handlePaste);
		return () => {
			window.removeEventListener("paste", handlePaste);
		};
	}, [handlePaste]);

	const handleFile = useCallback(
		(file) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				const img = new Image();
				img.onload = () => {
					const { width, height } = img;
					if (width <= maxDimensions.width && height <= maxDimensions.height) {
						const fileSizeInMB = file.size / (1024 * 1024);
						if (fileSizeInMB <= maxSizeInMB) {
							const isValidFileType = acceptedFormats.some((ext) =>
								file.name ? file.name.toLowerCase().endsWith(ext) : file.type.startsWith("image/")
							);
							if (isValidFileType) {
								setAcceptedFiles([file]);
								if (hideGuideText) {
									setShowGuideText(false);
								}
								if (onFileUploaded) {
									onFileUploaded(file);
								}
							} else {
								alertInvalidFile();
							}
						} else {
							alertInvalidFile();
						}
					} else {
						alertInvalidFile();
					}
				};
				img.onerror = () => {
					console.error("이미지 에러");
				};
				img.src = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		[maxDimensions, maxSizeInMB, acceptedFormats, hideGuideText, onFileUploaded]
	);

	const onDrop = useCallback(
		(files) => {
			if (files[0]) {
				handleFile(files[0]);
			}
		},
		[handleFile]
	);

	const alertInvalidFile = () => {
		window.alert({
			title: "이미지 업로드 실패했어요.",
			content: `<p>업로드 한 이미지 파일 형식이 지원되는지,<br> 파일 크기가 너무 크지 않은지 확인해 주세요.</p>
						<span class="sub-message">업로드 가능 이미지 형식 : JPG(JPEG), PNG, GIF, BMP</span>`,
			btnClassName: "primary",
			alertBtnText: "확인"
		});
	};

	const removeFile = () => {
		setAcceptedFiles([]);
		setShowGuideText(true);

		if (onFileRemoved) {
			onFileRemoved();
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: {
			"image/jpeg": [".jpeg", ".jpg"],
			"image/png": [".png"],
			"image/gif": [".gif"],
			"image/bmp": [".bmp"]
		},
		noClick: true // 클릭 이벤트를 비활성화하여 드래그앤드롭 영역에서 클릭으로 다이얼로그가 열리지 않도록 설정
	});

	const handleButtonClick = () => {
		document.querySelector('input[type="file"]').click();
	};

	return (
		<div className="file-upload">
			{acceptedFiles.map((file, index) => (
				<div key={index} className="file-item">
					<div className="preview">
						<img src={URL.createObjectURL(file)} alt={file.name} className="preview-img" />
					</div>
					{showDeleteButton && (
						<button type="button" className="btn-delete" onClick={removeFile}>
							<i className="ico ico-cancel-md"></i>
							<span className="sr-only">삭제</span>
						</button>
					)}
				</div>
			))}
			<div {...getRootProps()} className={`dropzone ${isDragActive ? "active" : ""}`}>
				<input {...getInputProps()} />
				<div className="upload-area">
					{showGuideText && (
						<div className="guide-text">
							<p className="text-md">Ctrl + V로 클립보드 이미지를 붙여넣으세요.</p>
							<p className="text-md mt-1">
								이 영역에 마우스로 끌어 올리거나 <br className="d-down-sm" />
								<button type="button" className="btn btn-file" onClick={handleButtonClick}>
									파일을 직접 선택
								</button>
								할 수도 있습니다.
							</p>
							<p className="text-md mt-2">
								<span>최대 20MB 이하 크기의 이미지 파일 등록 가능</span>
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Dropzone;
