// routesConfig.js
import HomePage from "@/pages/home/HomePage";
import IntroPage from "@/pages/home/IntroPage";

import LoginPage from "@/pages/account/LoginPage";
import FindidPage from "@/pages/account/FindidPage";
import FindidResultPage from "@/pages/account/FindidResultPage";
import FindpwPage from "@/pages/account/FindpwPage";

import JoinPage from "@/pages/account/JoinPage";
import MiddlePage from "@/pages/account/MiddlePage";
import DashboardPage from "@/pages/work/DashboardPage";
import WorkspacePage from "@/pages/work/WorkspacePage";
import DeletePage from "@/pages/work/DeletePage";

// import SettingProfilePage from "@/pages/setting/SettingProfilePage";
import SettingAccountPage from "@/pages/setting/SettingAccountPage";
import SettingLicensePage from "@/pages/setting/SettingLicensePage";
import SettingActivityPage from "@/pages/setting/SettingActivityPage";

import CustomerSupport from "@/pages/support/CustomerSupport";
import ContentPage from "@/pages/support/ContentPage";

import Img2htmlAddPage from "@/pages/tool/img2html/Img2htmlAddPage";
// import Img2htmlWorkDetailPage from "@/pages/tool/img2html/Img2htmlWorkDetailPage";

import ContrastAddPage from "@/pages/tool/contrast/ContrastAddPage";
// import ContrastWorkDetailPage from "@/pages/tool/contrast/ContrastWorkDetailPage";

import SeoAddPage from "@/pages/tool/seo/SeoAddPage";
// import SeoWorkDetailPage from "@/pages/tool/seo/SeoWorkDetailPage";

import LinterPage from "@/pages/tool/linter/LinterPage";
// import LinterAddPage from "@/pages/tool/linter/LinterAddPage";
// import LinterWorkDetailPage from "@/pages/tool/linter/LinterWorkDetailPage";
import LinterCodeComparePage from "@/pages/tool/linter/LinterCodeComparePage";

import TableAddPage from "@/pages/tool/table/TableAddPage";
// import TableWorkDetailStep from "@/pages/tool/table/TableWorkDetailPage";

import ErrorPage from "@/pages/error/ErrorPage";

import SharePage from "@/pages/share/SharePage";
import RedirectLandingPage from "@/pages/share/RedirectLandingPage";

import GuidePage from "@/pages/Guide";

import NaverRedirectURL from "@/services/core/login/NaverCallback";
import GoogleRedirectURL from "@/services/core/login/GoogleCallback";
import { NullPage } from "@/services/pages/nullpage/nullpage";

import scrollToTop from "@/services/util/scrollToTop";
import TermsOfService from "@/services/pages/Terms/TermsOfService";
import { PrivacyPolicy } from "@/services/pages/Terms/PrivacyPolicy";
import SeoWorkDetailMiddlePage from "@/pages/tool/seo/SeoWorkDetailMiddlePage";
import ContrastWorkMiddlePage from "@/pages/tool/contrast/ContrastWorkMiddlePage";
import Img2htmlWorkMiddlePage from "@/pages/tool/img2html/Img2htmlWorkMiddlePage";
import TableWorkDetailMiddlePage from "@/pages/tool/table/TableWorkDetailMiddlePage";

// 커뮤니티
import CommunityPage from "@/pages/community/CommunityPage";
import ResourcePage from "@/pages/community/ResourcePage";

const JoinWithScrollToTop = scrollToTop(JoinPage);

const routesConfig = [
	{
		path: "/guide",
		component: GuidePage,
		layout: "",
		meta: {
			title: "마크업 가이드"
		}
	},
	{
		path: "/",
		component: HomePage,
		layout: "home",
		meta: {
			mobileMode: true
			// title: "웹 접근성 AI 마법사, 앨리",
			// ogTitle: "웹 접근성 AI 마법사, 앨리",
			// description: "지금 베타테스터 신청하면 사용권 100% 증정",
			// ogDescription: "웹 접근성 작업 앨리와 함께 부스트업!",
			// keywords: "개발, 웹 개발, 퍼블리싱, 마크업, 웹 접근성, a11y, 인시퀀스, 앨리, AI, 인공지능, SEO",
			// ogUrl: "https://a11y.co.kr",
			// ogImage: "https://a11y.co.kr:7771/logo-a11y-kakao.png"
		}
	},
	{
		path: "/intro",
		component: IntroPage,
		layout: "home",
		meta: {
			mobileMode: true
		}
	},
	{
		path: "/community",
		component: CommunityPage,
		layout: "community",
		meta: {
			title: "커뮤니티",
			summaryTitle: "커뮤니티",
			mobileMode: true
		},
		requiresAuth: false
	},
	{
		path: "/resource",
		component: ResourcePage,
		layout: "community",
		meta: {
			title: "웹 접근성 리소스",
			summaryTitle: "웹 접근성 리소스",
			mobileMode: true
		},
		requiresAuth: false
	},
	{
		path: "/login",
		component: LoginPage,
		layout: "full",
		meta: {
			title: "로그인",
			mobileMode: true
		}
	},
	{
		path: "/findid",
		component: FindidPage,
		layout: "full",
		meta: {
			title: "계정찾기",
			mobileMode: true
		}
	},
	{
		path: "/findid/result",
		component: FindidResultPage,
		layout: "full",
		meta: {
			title: "계정찾기 결과",
			mobileMode: true
		}
	},
	{
		path: "/findpw",
		component: FindpwPage,
		layout: "full",
		meta: {
			title: "비밀번호 재설정",
			mobileMode: true
		}
	},
	{
		path: "/join",
		component: JoinWithScrollToTop, //JoinPage,
		layout: "full",
		meta: {
			title: "회원가입",
			mobileMode: true
		}
	},
	{
		path: "/middlePage",
		component: MiddlePage,
		layout: "full",
		meta: {
			title: "회원가입 완료",
			mobileMode: true
		}
	},
	{
		path: "/dashboard",
		component: DashboardPage,
		layout: "default",
		meta: {
			title: "대시보드"
		},
		requiresAuth: true
	},
	{
		path: "/workspace",
		component: WorkspacePage,
		layout: "default",
		meta: {
			title: "내 작업공간"
		},
		requiresAuth: true
	},
	{
		path: "/delete",
		component: DeletePage,
		layout: "default",
		meta: {
			title: "휴지통"
		},
		requiresAuth: true
	},
	{
		path: "/settings",
		component: SettingActivityPage, // 추가된 부분
		layout: "default",
		meta: {
			title: "앨리지수 | 설정",
			summaryTitle: "앨리지수"
		},
		requiresAuth: true
	},
	{
		path: "/setting/activity",
		component: SettingActivityPage,
		layout: "default",
		meta: {
			title: "앨리지수 | 설정",
			summaryTitle: "앨리지수"
		},
		requiresAuth: true
	},
	{
		path: "/setting/account",
		component: SettingAccountPage,
		layout: "default",
		meta: {
			title: "내 계정 | 설정",
			summaryTitle: "내 계정"
		},
		requiresAuth: true
	},
	{
		path: "/setting/license",
		component: SettingLicensePage,
		layout: "default",
		meta: {
			title: "사용권 | 설정",
			summaryTitle: "사용권"
		},
		requiresAuth: true
	},
	{
		path: "/help",
		component: CustomerSupport,
		layout: "default",
		meta: {
			title: "도움말 센터",
			link: "/help"
		},
		requiresAuth: true
	},
	{
		path: "/contents",
		component: ContentPage,
		layout: "default",
		meta: {
			title: "이벤트 프로모션",
			summaryTitle: "이벤트"
		}
		// requiresAuth: true
	},
	{
		path: "/help/img2html-output",
		defaultComponent: RedirectLandingPage,
		redirect: "/contents?to=help/ir-기법이란&title=IR+기법이란",
		isExternal: true
	},
	{
		path: "/tool/img2html/add",
		component: Img2htmlAddPage,
		layout: "community",
		meta: {
			title: "이미지 업로드 | 이미지 HTML 변환기",
			summaryTitle: "HTML 변환기",
			mobileMode: true
		},
		requiresAuth: true
	},
	{
		path: "/tool/img2html/",
		component: Img2htmlAddPage,
		layout: "community",
		meta: {
			title: "이미지 업로드 | 이미지 HTML 변환기",
			summaryTitle: "HTML 변환기"
		},
		requiresAuth: true
	},
	{
		path: "/tool/img2html/work-detail/:workId",
		component: Img2htmlWorkMiddlePage,
		layout: "details",
		meta: {
			title: "작업 상세 | 이미지 HTML 변환기",
			summaryTitle: "HTML 변환기"
		},
		requiresAuth: true
	},
	{
		path: "/tool/contrast",
		component: ContrastAddPage,
		layout: "community",
		meta: {
			title: "이미지 업로드 | 이미지 명도대비 최적화",
			summaryTitle: "명도대비 최적화"
		},
		requiresAuth: true
	},
	{
		path: "/tool/contrast/add",
		component: ContrastAddPage,
		layout: "community",
		meta: {
			title: "이미지 업로드 | 이미지 명도대비 최적화",
			summaryTitle: "명도대비 최적화",
			mobileMode: true
		},
		requiresAuth: true
	},
	{
		path: "/tool/contrast/work-detail/:workId",
		component: ContrastWorkMiddlePage,
		layout: "details",
		meta: {
			title: "진단 및 분석 | 이미지 명도대비 최적화",
			summaryTitle: "명도대비 최적화"
		},
		requiresAuth: true
	},
	{
		path: "/tool/seo/",
		component: SeoAddPage,
		layout: "community",
		meta: {
			title: "코드 입력 | SEO 메타태그 생성기",
			summaryTitle: "메타태그 생성기"
		},
		requiresAuth: true
	},
	{
		path: "/tool/seo/add",
		component: SeoAddPage,
		layout: "community",
		meta: {
			title: "코드 입력 | SEO 메타태그 생성기",
			summaryTitle: "메타태그 생성기",
			mobileMode: true
		},
		requiresAuth: true
	},
	{
		path: "/tool/seo/work-detail/:workId",
		component: SeoWorkDetailMiddlePage,
		layout: "details",
		meta: {
			title: "생성 결과 | SEO 메타태그 생성기",
			summaryTitle: "메타태그 생성기"
		},
		requiresAuth: true
	},
	{
		path: "/tool/linter",
		component: LinterPage,
		layout: "details",
		meta: {
			title: "코드 입력 | 웹 접근성 문법 검사기",
			summaryTitle: "웹 접근성 검사기"
		},
		requiresAuth: true
	},

	{
		path: "/tool/linter/compare",
		component: LinterCodeComparePage,
		layout: "details",
		meta: {
			title: "코드 비교 | 웹 접근성 문법 검사기",
			summaryTitle: "웹 접근성 검사기"
		},
		requiresAuth: true
	},
	{
		path: "/tool/table/add",
		component: TableAddPage,
		layout: "community",
		meta: {
			title: "코드 입력 | 표 접근성 최적화",
			summaryTitle: "표 접근성 최적화",
			mobileMode: true
		},
		requiresAuth: true
	},
	{
		path: "/tool/table/work-detail/:workId",
		component: TableWorkDetailMiddlePage,
		layout: "details",
		meta: {
			title: "표 편집 및 캡션 생성 | 표 접근성 최적화",
			summaryTitle: "표 접근성 최적화"
		},
		requiresAuth: true
	},
	{
		path: "/error",
		component: ErrorPage,
		layout: "",
		meta: {
			title: "오류안내",
			mobileMode: true
		}
	},
	// 이메일 체크용 페이지
	{
		path: "/check/token",
		component: NullPage,
		layout: "",
		meta: {
			title: "리다이렉션중",
			mobileMode: true
		}
	},
	// 네이버 콜백
	{
		path: "/naver/callback",
		component: NaverRedirectURL,
		layout: "",
		meta: {
			title: "로그인중",
			mobileMode: true
		}
	},
	// 구글콜백
	{
		path: "/google/callback",
		component: GoogleRedirectURL,
		layout: "",
		meta: {
			title: "로그인중",
			mobileMode: true
		}
	},
	{
		path: "/TermsOfService",
		component: TermsOfService,
		layout: "",
		meta: {
			title: "이용약관",
			mobileMode: true
		}
	},
	{
		path: "/PrivacyPolicy",
		component: PrivacyPolicy,
		layout: "",
		meta: {
			title: "개인정보처리방침",
			mobileMode: true
		}
	},
	{
		path: "*",
		component: ErrorPage,
		layout: "",
		meta: {
			title: "오류안내",
			mobileMode: true
		}
	},
	{
		path: "/share/:pathParam",
		component: SharePage,
		layout: "",
		meta: {
			excludeMeta: true, // LayoutWrapper에서 지정한 메타태그 예외처리
			title: "[a11y.co.kr]"
		}
	}
];

export default routesConfig;
