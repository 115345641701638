import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useLoginForm } from "@/services/core/login/LoginHandle";
import loginWithGoogle from "@/services/core/login/GoogleLogin";
import NaverWithGoogle from "@/services/core/login/NaverLogin";
import { emailRegex } from "@/services/util/const";

function LoginForm({ className, onClose = () => {}, onLoginSuccess = () => {} }) {
	const emailInputRef = useRef(null);
	const passwordInputRef = useRef(null);

	const { userEmail, setUserEmail, userPw, setUserPw, error, handleLogin, emailTrim, pwTrim } = useLoginForm(
		emailInputRef,
		passwordInputRef
	);

	// handleRedirect 함수 정의
	const handleRedirect = (path) => {
		onClose(); // 팝업 닫기 등 처리
		window.location.href = path;
	};

	return (
		<div className={`account-body login-page ${className}`}>
			<div className="btn-group btn-group-column">
				<button type="button" className="btn btn-xl btn-outline-gray google" onClick={loginWithGoogle}>
					Google 계정으로 로그인
				</button>
				<button type="button" className="btn btn-xl btn-outline-gray naver" onClick={NaverWithGoogle}>
					네이버로 로그인
				</button>
			</div>
			<p>또는</p>
			<form
				onSubmit={async (e) => {
					e.preventDefault();

					// handleLogin을 먼저 실행하고 결과에 따라 처리
					const loginResult = await handleLogin(e);

					if (!loginResult) {
						// 로그인 실패 시 처리
						return;
					}
					// 로그인 성공 시 부모 컴포넌트에 알림
					onLoginSuccess();
				}}
			>
				<fieldset>
					<legend className="sr-only">로그인</legend>
					<div className="form-group">
						<dl className="form-item">
							<dt>
								<label htmlFor="user-email">이메일 아이디</label>
							</dt>
							<dd>
								<div className="input-group-form">
									<input
										ref={emailInputRef}
										type="text"
										id="user-email"
										className="form-control"
										value={userEmail}
										placeholder="이메일을 입력해주세요."
										onChange={(e) => setUserEmail(e.target.value)}
									/>
								</div>
							</dd>
						</dl>
						{userEmail.trim() && !emailRegex.test(userEmail) && (
							<p className="error-text">올바른 이메일을 입력해주세요.</p>
						)}
						{!userEmail.trim() && !emailTrim && <p className="error-text">이메일 아이디를 입력해주세요.</p>}
						<dl className="form-item">
							<dt>
								<label htmlFor="user-pw">비밀번호</label>
							</dt>
							<dd>
								<div className="input-group-form">
									<input
										ref={passwordInputRef}
										type="password"
										id="user-pw"
										className="form-control"
										value={userPw}
										autoComplete="current-password"
										placeholder="비밀번호를 입력해주세요."
										onChange={(e) => setUserPw(e.target.value)}
									/>
								</div>
							</dd>
						</dl>
						{error && <p className="error-text">{error}</p>}
					</div>
				</fieldset>
				<div className="btn-group btn-group-flex">
					<button className="btn btn-xl btn-primary" type="submit">
						<span>로그인</span>
					</button>
				</div>
			</form>
			<ul className="idpw-find-list">
				<li>
					<button className="btn btn-lg join" onClick={() => handleRedirect("/join")}>
						<span>이메일로 회원가입</span>
					</button>
				</li>
				<li>
					<Link to="https://tally.so/r/nrK65o" target="_blank" title="새창" className="btn btn-lg find-id">
						<span>계정찾기</span>
					</Link>
				</li>
				<li>
					<button className="btn btn-lg reset-pw" onClick={() => handleRedirect("/findpw")}>
						<span>비밀번호 재설정</span>
					</button>
				</li>
			</ul>
		</div>
	);
}

export default LoginForm;
