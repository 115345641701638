import React from "react";

const Button = ({
	icon,
	text,
	loadingText,
	isLoading,
	className,
	size,
	color,
	type = "button",
	id,
	onClick,
	disabled
}) => {
	const classes = `btn btn-${size} btn-${color} ${className || ""} ${isLoading ? "btn-loading" : ""}`;
	const iconSizeClass = size ? `ico-loading-${size}` : "";
	const iconClasses = icon ? `ico ${iconSizeClass} ${icon.classes || ""}` : "";
	const loadingSpinnerSizeClass = size ? `loading-spinner-${size}` : "";
	const loadingSpinnerClasses = `loading-spinner ${loadingSpinnerSizeClass}`;

	const actualLoadingText = loadingText === true ? text : loadingText;

	const renderLoadingContent = () => (
		<>
			<span className={loadingSpinnerClasses}></span>
			{actualLoadingText && <span className="btn-text">{actualLoadingText}</span>}
		</>
	);

	const renderContent = () => {
		if (isLoading) {
			return loadingText || text ? renderLoadingContent() : <span className={loadingSpinnerClasses}></span>;
		} else {
			return (
				<>
					{icon && <i className={iconClasses}></i>}
					{text && <span className="btn-text">{text}</span>}
				</>
			);
		}
	};

	const buttonProps = {
		type,
		className: classes,
		disabled: isLoading || disabled,
		...(id && { id }), // id가 정의된 경우에만 추가
		...(onClick && { onClick }) // onClick이 정의된 경우에만 추가
	};

	return <button {...buttonProps}>{renderContent()}</button>;
};

export default Button;
