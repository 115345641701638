import React, { createContext, useState, useContext, useRef } from "react";
import ModalAlert from "@/components/modal/ModalAlert";
import ModalConfirm from "@/components/modal/ModalConfirm";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
	const [alertOptions, setAlertOptions] = useState({ isOpen: false, message: "", customClass: "" });
	const [confirmOptions, setConfirmOptions] = useState({
		isOpen: false,
		message: "",
		onConfirm: null,
		confirmButtonText: "확인",
		cancelButtonText: "취소"
	});
	const lastFocusedElement = useRef(null);

	const showAlert = (options) => {
		lastFocusedElement.current = document.activeElement;
		setAlertOptions({ isOpen: true, ...options });
	};

	const closeAlert = () => {
		setAlertOptions({ isOpen: false, message: "", customClass: "" });
		if (lastFocusedElement.current) {
			lastFocusedElement.current.focus();
		}
	};

	const showConfirm = (options) => {
		lastFocusedElement.current = document.activeElement;
		setConfirmOptions({
			isOpen: true,
			confirmButtonText: "확인",
			cancelButtonText: "취소",
			onConfirm: () => {}, // 빈 함수로 초기화
			onCancel: () => {}, // 취소 버튼 클릭 시 실행될 함수 추가
			...options
		});
	};

	const closeConfirm = () => {
		setConfirmOptions({
			isOpen: false,
			message: "",
			content: "",
			onConfirm: null,
			confirmButtonText: "확인",
			cancelButtonText: "취소",
			buttonStatus: ""
		});
		if (lastFocusedElement.current) {
			lastFocusedElement.current.focus();
		}
	};

	window.alert = showAlert;
	window.confirm = showConfirm;

	return (
		<ModalContext.Provider value={{ closeAlert, closeConfirm }}>
			{children}
			<ModalAlert {...alertOptions} onClose={closeAlert} />
			<ModalConfirm
				{...confirmOptions}
				onConfirm={() => {
					confirmOptions.onConfirm && confirmOptions.onConfirm(true);
					closeConfirm();
				}}
				onCancel={() => {
					confirmOptions.onCancel && confirmOptions.onCancel(false);
					closeConfirm();
				}}
			/>
		</ModalContext.Provider>
	);
};

export const useModal = () => useContext(ModalContext);
