import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess } from "@/services/store/action/LoginAction"; // 로그인 성공 액션 임포트
import PopupLayout from "@/components/popup/PopupLayout";
import LoginForm from "@/components/form/LoginForm";

const PopupLogin = ({ isOpen, closePopup, to, onLoginSuccess }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleClosePopup = () => {
		console.log("Popup closed");
		closePopup();
	};

	const handleLoginSuccess = (userData) => {
		console.log("Login success", userData);
		handleClosePopup(); // 팝업을 닫습니다.
		dispatch(loginSuccess(userData)); // 로그인 성공 후 Redux에 상태 업데이트
		onLoginSuccess(userData); // 부모 컴포넌트로 로그인 성공 알림

		if (to) {
			console.log("Navigating to:", to);
			navigate(to);
		} else {
			// console.log("Navigating to: /dashboard");
			navigate("/dashboard");
		}
	};

	return (
		<PopupLayout
			isOpen={isOpen}
			closePopup={closePopup}
			title="로그인"
			size="sm"
			className="login-popup-page"
			footerContent={null}
		>
			<LoginForm className="login-popup-area" onClose={handleClosePopup} to={to} onLoginSuccess={handleLoginSuccess} />
		</PopupLayout>
	);
};

export default PopupLogin;
