import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCookie } from "@/services/cookies/UserCookies";
import MyPageGet from "../mypage/MyPageGet";
import { LicenseExamination } from "../mypage/LicenseExamination";
import { DataCount } from "../mypage/MyDataCount";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { DashBoard } from "../workspace/DashBoard";
import { setDashboardData } from "@/services/store/action/DashBoard";
import { loginSuccess } from "@/services/store/action/LoginAction"; // 로그인 성공 액션 추가
import LoadingPage from "@/services/util/LoadingPage";
import api from "@/services/util/Interceptor";
import { API_URL2 } from "../url/URL";
import { CookiesMaxAge } from "@/services/util/const";
import { alertFormatDate } from "@/services/util/TransForm";

const GoogleRedirectURL = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchAuthData = async () => {
			setIsLoading(true);
			const error = new URL(window.location.href).searchParams.get("error");
			if (error) {
				setIsLoading(false);
				navigate("/login");
				return false;
			}
			try {
				const code = new URL(window.location.href).searchParams.get("code");
				const state = new URL(window.location.href).searchParams.get("state");
				const response = await api.get(`${API_URL2}user/google/callback?code=${code}&state=${state}`);
				const data = response.data;

				if (response.status === 200) {
					// 토큰 저장
					setCookie("token", data.access_token, { path: "/", maxAge: CookiesMaxAge });

					// 로그인 성공 시 사용자 데이터를 Redux에 설정
					dispatch(loginSuccess(data.user)); // 구글 로그인 성공 후 상태 업데이트

					// 필요한 데이터 처리 완료 후 리다이렉트
					await MyPageGet(dispatch);
					await LicenseExamination(dispatch);

					const dataCountResult = await DataCount();
					dispatch(setDataCount(dataCountResult));

					const dashboardData = await DashBoard(null, 10, "", null, 1, true);
					dispatch(setDashboardData(dashboardData));

					// 데이터 처리가 완료된 후 리다이렉트
					navigate("/dashboard");
				} else if (response.status === 201) {
					// 가입되지 않은 사용자는 회원 가입 페이지로 리다이렉트
					navigate("/join", { state: { fromAuth: true, userData: data } });
				}
			} catch (err) {
				setError(err);
				if (err.response?.data?.message?.msg === "탈퇴한 회원입니다.") {
					const dateString = err.response.data.message.date;
					const formattedDate = alertFormatDate(dateString);
					alert({ title: `[탈퇴] 탈퇴한 사용자입니다.`, message: `${formattedDate} 이후에 가입이 가능합니다.` });
					navigate("/");
					return;
				}

				if (err.response?.data?.message === "차단한 회원입니다.") {
					alert({ message: `[블럭] 계정이 잠겼습니다. 운영팀에 문의하세요.` });
					navigate("/");
					return;
				}

				navigate("/error");
			} finally {
				setIsLoading(false);
			}
		};

		fetchAuthData();
	}, [navigate, dispatch]);

	if (isLoading) {
		return (
			<div>
				<LoadingPage />
			</div>
		);
	}

	return (
		<div>
			<LoadingPage />
		</div>
	);
};

export default GoogleRedirectURL;
