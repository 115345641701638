import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidebarLogo from "@/assets/images/logo-sm@2x.png";
import imgProfile from "@/assets/images/img-profile-sm@2x.png";

import PopupLayer from "@/components/popup/PopupLayer";
import PopupSearch from "@/pages/popup/PopupSearch";
import TallyIframe from "@/components/plugins/TallyIframe";
import PopupLayout from "@/components/popup/PopupLayout";
import usePopup from "@/hooks/usePopup";
import MyPageGet, { UserDataComponent } from "@/services/core/mypage/MyPageGet";
import { useSelector, useDispatch } from "react-redux";
import LogoutButton from "@/services/util/LogoutButton";
import { getCookie } from "@/services/cookies/UserCookies";
import "@/services/css/input.css";
import { DataCount } from "@/services/core/mypage/MyDataCount";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { selectSid } from "@/services/store/action/SelectAction";

const NavItem = React.forwardRef(({ to, iconClass, text, count, isLink, onClick, isSelected, children }, ref) => {
	const handleClick = (e) => {
		if (onClick) {
			onClick();
		}
	};
	return (
		<li className={isSelected ? "on" : ""}>
			{isLink ? (
				<a href={to} target="_blank" rel="noopener noreferrer" onClick={handleClick} ref={ref} title="새창">
					<span className="tit">
						<i className={iconClass}></i>
						<span className="text">{text}</span>
					</span>
					{count != null && <span className="count">{count}</span>}
					<span>
						<i className="ico ico-link-md"></i>
					</span>
					{children}
				</a>
			) : (
				<Link to={to} onClick={handleClick} ref={ref}>
					<span className="tit">
						<i className={iconClass}></i>
						<span className="text">{text}</span>
					</span>
					{count != null && <span className="count">{count}</span>}
					{children}
				</Link>
			)}
		</li>
	);
});

export default function Sidebar() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const select = useSelector((state) => state.sidSelect?.text);
	const getUserData = useSelector((state) => state.user?.userData);
	const dataCount = useSelector((state) => state.dataCount.dataCount?.data);
	const allCount = dataCount?.allCount || null;
	const trashCount = dataCount?.trashCount || null;
	const [search, setSearch] = useState("");

	const topNavItems = [
		{
			to: "/contents?to=notice&title=소식·이벤트",
			iconClass: "ico ico-alarm-md",
			text: "소식·이벤트",
			label: "NEW",
			labelClass: "new"
		},
		{ to: "/contents?to=help&title=도움말+센터", iconClass: "ico ico-help-md", text: "도움말 센터" },
		{ to: "/community", iconClass: "ico ico-community-md", text: "앨리 커뮤니티" },
		{ to: "/resource", iconClass: "ico ico-resource-md", text: "웹 접근성 리소스", label: "NEW", labelClass: "new" }
	];

	const midNavItems = [
		{
			to: "/dashboard",
			iconClass: "ico ico-dashboard-md",
			text: "대시보드"
		},
		{ to: "/workspace", iconClass: "ico ico-workspace-md", text: "내 작업공간", count: allCount || null },
		{ to: "/delete", iconClass: "ico ico-delete-md", text: "휴지통", count: trashCount || null }
	];

	const toolItems = [
		{ to: "/tool/img2html/add", iconClass: "ico ico-img2html-md", text: "이미지 HTML 변환기" },
		{ to: "/tool/contrast/add", iconClass: "ico ico-contrast-md", text: "이미지 명도대비 최적화" },
		{ to: "/tool/seo/add", iconClass: "ico ico-seo-md", text: "SEO 메타태그 생성기" },
		{ to: "/tool/table/add", iconClass: "ico ico-table-md", text: "표 접근성 최적화" }
	];

	// const checkTokenAndNavigate = () => {
	// 	const token = getCookie("token");
	// 	if (token) {
	// 		navigate("/");
	// 	} else {
	// 		navigate("/");
	// 	}
	// };

	// const [selectedNavItem, setSelectedNavItem] = useState(null);
	const onItemClick = (item) => {
		dispatch(selectSid(item.text));
	};

	// 검색 팝업
	const popupSearch = usePopup();
	const handleCount = async () => {
		await MyPageGet(dispatch);
		const dataCountResult = await DataCount();
		dispatch(setDataCount(dataCountResult));
	};

	useEffect(() => {
		handleCount();
	}, []);

	// 설정 팝업
	const [isSettingPopupOpen, setIsSettingPopupOpen] = useState(false);
	const settingButtonRef = useRef(null);

	const handleButtonMouseDown = (event) => {
		event.stopPropagation();
	};
	const closeSettingPopup = () => {
		setIsSettingPopupOpen(false);
	};
	const toggleSettingPopup = () => {
		setIsSettingPopupOpen(!isSettingPopupOpen);
	};

	const { isOpen: isOpinionPopup, open: openOpinionPopup, close: closeOpinionPopup } = usePopup();

	// const currentUrl = window.location.href;
	const currentTitle = document.title;

	const userData = {
		userEmailId: getUserData?.data?.email,
		userName: getUserData?.data?.name,
		userTel: getUserData?.data?.phone,
		currentTitle: currentTitle,
		currentUrl: window.location.href
	};

	useEffect(() => {
		if (location.search.includes("popup=feedback")) {
			openOpinionPopup();
		}
	}, [location.search, openOpinionPopup]);

	const handleCloseOpinionPopup = () => {
		closeOpinionPopup();
		const urlWithoutQuery = location.pathname;
		navigate(urlWithoutQuery, { replace: true });
	};

	const isActive = (to) => {
		const [path, search] = to.split("?");
		const currentSearch = new URLSearchParams(location.search);
		const targetSearch = new URLSearchParams(`?${search}`);
		return location.pathname === path && currentSearch.get("to") === targetSearch.get("to");
	};

	return (
		<>
			<header className="sidebar" id="sidebar">
				<div className="sidebar-header">
					<h1 className="sidebar-title">
						<Link className="sidebar-logo" to="/dashboard">
							<img src={SidebarLogo} alt="A11Y beta" />
						</Link>
					</h1>
				</div>
				<div className="sidebar-body">
					<div className="site-search">
						<button type="button" className="btn btn-search" onClick={(e) => popupSearch.open(e)}>
							<span>문서 또는 서비스 검색</span>
							<i className="ico ico-search-gray"></i>
							<span className="sr-only">검색</span>
						</button>
						<nav className="nav-area-top">
							<ul className="list list-sidebar">
								{topNavItems.map((item, index) => (
									<NavItem
										key={index}
										to={item.to}
										iconClass={item.iconClass}
										text={item.text}
										isLink={item.isLink}
										onClick={() => onItemClick(item)}
										isSelected={isActive(item.to)} // 수정된 isActive 함수 적용
									>
										{item.label && <span className={`label ${item.labelClass}`}>{item.label}</span>}
									</NavItem>
								))}
							</ul>
						</nav>
					</div>
					<nav className="nav-area-mid">
						<ul className="list-sidebar">
							{midNavItems.map((item, index) => (
								<NavItem
									key={index}
									to={item.to}
									iconClass={item.iconClass}
									text={item.text}
									num={item.num}
									count={item.count}
									onClick={() => onItemClick(item)}
									isSelected={location.pathname.startsWith(item.to)}
								/>
							))}
						</ul>
					</nav>
					<nav id="tools-area" className="nav-area-tools">
						<ul className="list-sidebar">
							{toolItems.map((item, index) => (
								<NavItem
									key={index}
									to={item.to}
									iconClass={item.iconClass}
									text={item.text}
									onClick={() => onItemClick(item)}
									isSelected={location.pathname.startsWith(item.to)}
								/>
							))}
						</ul>
					</nav>
				</div>
				<div className="sidebar-footer">
					<div className="contact-area">
						<Link to="?popup=feedback" className="btn btn-sm">
							<i className="ico ico-pencil-md-gray"></i>
							의견 보내기
						</Link>
						<div className="line"></div>
						<Link to="https://tally.so/r/w4kBDO" target="_blank" title="새창" className="btn btn-sm">
							<i className="ico ico-handshake-md-gray"></i>
							제휴 문의
						</Link>
						<Link to="/setting/license" className="btn btn-md btn-outline-primary">
							<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9 1.15417V4.15417" stroke="#1364FF" strokeWidth="1.3" strokeLinecap="round" />
								<path d="M9 10.1542V13.1542" stroke="#1364FF" strokeWidth="1.3" strokeLinecap="round" />
								<path d="M15 7.15417L12 7.15417" stroke="#1364FF" strokeWidth="1.3" strokeLinecap="round" />
								<path d="M6 7.15417L3 7.15417" stroke="#1364FF" strokeWidth="1.3" strokeLinecap="round" />
								<path d="M13.25 11.4003L11.4307 9.58092" stroke="#1364FF" strokeWidth="1.3" strokeLinecap="round" />
								<path d="M6.57812 4.72839L4.75878 2.90905" stroke="#1364FF" strokeWidth="1.3" strokeLinecap="round" />
								<path d="M1 15.1542L9.00271 7.15536" stroke="#1364FF" strokeWidth="1.3" strokeLinecap="round" />
								<path d="M11.4297 4.72839L13.249 2.90905" stroke="#1364FF" strokeWidth="1.5" strokeLinecap="round" />
							</svg>
							사용권 등록하기
						</Link>
					</div>
					<div className="user-info">
						<div className="user-info-area">
							<div className="profile">
								<button
									type="button"
									className="profile-name"
									onClick={toggleSettingPopup}
									onMouseDown={handleButtonMouseDown}
									ref={settingButtonRef}
								>
									<span className="profile-thumb">
										{getUserData && getUserData.data && getUserData.data.profileImg ? (
											<img src={`${getUserData.data.profileImg}`} alt={getUserData.data.name + " 프로필 이미지"} />
										) : (
											<img src={imgProfile} alt={" 프로필 이미지"} />
										)}
									</span>
									<span className="profile-name">
										{getUserData && getUserData.data && getUserData.data.name ? (
											<span className="name">{getUserData.data.name}</span>
										) : (
											<span className="name">로딩중</span>
										)}
										<span className="more-info">
											<i className={isSettingPopupOpen ? "ico ico-arrow-up-sm" : "ico ico-arrow-down-sm"}></i>
											<span className="sr-only">더보기</span>
										</span>
									</span>
								</button>
							</div>
						</div>
						<div className="use-info-num">
							<Link to="/setting/license">
								<i className="ico ico-tool-md-primary"></i>
								<span className="use-num">
									<span className="num">{getUserData?.data?.count ?? "0"}</span>장
								</span>
							</Link>
						</div>
					</div>
				</div>
			</header>
			{/* 검색 모달 팝업 */}
			<PopupSearch isOpen={popupSearch.isOpen} closePopup={popupSearch.close} search={search} setSearch={setSearch} />
			{/* 설정 레이어 팝업 */}
			<PopupLayer
				className="setting-popup"
				isOpen={isSettingPopupOpen}
				closePopup={closeSettingPopup}
				title="설정팝업"
				headerContent={null}
				footerContent={null}
				buttonRef={settingButtonRef}
				position="top"
			>
				<div className="info-detail">
					<div className="btn-group">
						<Link
							to="/settings"
							onClick={(e) => {
								closeSettingPopup();
							}}
							className="btn btn-sm"
						>
							설정
						</Link>
						<LogoutButton />
					</div>
				</div>
			</PopupLayer>
			{/* 의견 보내기 팝업 */}
			<PopupLayout
				className="send-feedback-popup"
				size="sm"
				isOpen={isOpinionPopup}
				closePopup={handleCloseOpinionPopup}
				footerContent={null}
			>
				<TallyIframe className="send-feedback" dataTallyOpen="woGYxb" frameTitle="의견 보내기" userData={userData} />
			</PopupLayout>
		</>
	);
}
