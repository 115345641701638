import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
// import PromotionBanner from "@/components/promotion/PromotionBanner";
import FooterLanding from "@/components/layouts/FooterLanding";
import usePopup from "@/hooks/usePopup";
import PopupLogin from "@/pages/popup/PopupLogin";
import { getCookie } from "@/services/cookies/UserCookies";
// import { useNavigationFunctions } from "@/services/core/setting/Selector";
// import GuideHeader from "@/components/GuideHeader";
import ImgService1 from "@/assets/images/img-service1@2x.png";
import ImgService2 from "@/assets/images/img-service2@2x.png";
import ImgService3 from "@/assets/images/img-service3@2x.png";
import ImgService4 from "@/assets/images/img-service4@2x.png";
import IconService1 from "@/assets/images/ico-service1-lg@2x.png";
import IconService2 from "@/assets/images/ico-service2-lg@2x.png";
import IconService3 from "@/assets/images/ico-service3-lg@2x.png";
import IconService4 from "@/assets/images/ico-service4-lg@2x.png";
import CTASection from "./CTASection";

export default function IntroPage() {
	const token = getCookie("token");

	const popupLoginCommon = usePopup();
	const [isBannerHidden, setIsBannerHidden] = useState(true);
	const [isOnBlack, setIsOnBlack] = useState(false);
	const [isPromotionActive, setIsPromotionActive] = useState(false);
	const [timeRemaining, setTimeRemaining] = useState("");

	// 프로모션 마감 카운트다운
	const promotionStartTime = new Date("2024-06-14T00:00:00").getTime();
	const promotionEndTime = new Date("2024-07-18T00:00:00").getTime();
	const calculateTimeRemaining = useCallback(() => {
		const now = new Date().getTime();
		const difference = promotionEndTime - now;

		if (difference > 0) {
			const days = Math.floor(difference / (1000 * 60 * 60 * 24));
			const hours = String(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, "0");
			const minutes = String(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, "0");
			const seconds = String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(2, "0");
			setTimeRemaining(`D-${days} ${hours}시간 ${minutes}분 ${seconds}초`);
		} else {
			setIsPromotionActive(false);
			setTimeRemaining("");
		}
	}, [promotionEndTime]);

	useEffect(() => {
		const now = new Date().getTime();
		setIsPromotionActive(now >= promotionStartTime && now <= promotionEndTime);

		const interval = setInterval(calculateTimeRemaining, 1000);
		return () => clearInterval(interval);
	}, [calculateTimeRemaining, promotionStartTime, promotionEndTime]);

	const handleScroll = useCallback(() => {
		const scrollPosition = window.scrollY;
		const bannerHeight = 80; // 배너 높이
		const triggerPoint = 300; // 특정 지점 (픽셀 단위로 설정)

		const footerElement = document.querySelector("footer");
		const sectionIntroElement = document.querySelector(".section-intro");

		// 요소가 존재하는지 확인
		if (!footerElement || !sectionIntroElement) return;

		const sectionIntroTop = sectionIntroElement.offsetTop;
		const sectionIntroBottom = sectionIntroTop + sectionIntroElement.offsetHeight;
		const footerTop = footerElement.offsetTop;
		const footerBottom = footerTop + footerElement.offsetHeight;

		const windowBottom = scrollPosition + window.innerHeight;

		// 배너 보이기/숨기기
		if (scrollPosition > triggerPoint) {
			setIsBannerHidden(false);
		} else {
			setIsBannerHidden(true);
		}

		// section-intro와 겹치는 시점 감지
		if (windowBottom > sectionIntroTop && windowBottom < sectionIntroBottom + bannerHeight) {
			setIsOnBlack(true);
		} else if (windowBottom > footerTop && windowBottom < footerBottom + bannerHeight) {
			setIsOnBlack(true);
		} else {
			setIsOnBlack(false);
		}
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);

	useEffect(() => {
		if (token) {
		}
	}, [token]);
	// const {Img2htmlPage,dashboardPage,contrastAddPage,seoAddPage,tableAddPage}=useNavigationFunctions()

	return (
		<>
			<div className="intro-page">
				<section className="section section-problem">
					<div className="container-md">
						<div className="section-head">
							<h3 className="section-title">
								시각장애인은 어떻게
								<br className="d-down-sm" /> 웹 사이트를 이용할까?
								{/* 노안이 오면, 상지 장애인은, */}
							</h3>
						</div>
						<div className="section-body text-center pt-8">
							<p className="text-lg">
								모든 사람이 이해할 수 있는
								<br className="d-down-sm" /> 웹 콘텐츠란 어떤 것일까?
							</p>
							<p className="text-lg mt-2">
								웹 접근성을 고려한
								<br className="d-down-sm" /> 웹 콘텐츠 제작 기법이 따로 있다?
							</p>
						</div>
						<dl className="a11y-mean">
							<dt className="title2">웹 접근성이란?</dt>
							<dd className="box">
								<p className="text-lg mb-4 text-center">
									고령자나 장애가 있는 사람을 포함해 <br />
									<strong className="text-bold">모든 사용자가 웹 콘텐츠와 서비스를 접근</strong>하고
									<br />
									사용할 수 있도록 보장하는 것
								</p>
								<ul className="list list1">
									<li>더 많은 사용자를 확보할 수 있어요</li>
									<li>법적 문제를 피할 수 있어요 (장애인차별금지법)</li>
									<li>SEO가 개선돼요</li>
									<li>전반적인 사용성이 향상돼요</li>
									<li>사회적 책임을 다하여 브랜드 이미지가 좋아져요</li>
								</ul>
							</dd>
						</dl>
					</div>
				</section>
				<section className="section section-problem">
					<div className="container-md">
						<div className="section-head">
							<h3 className="section-title">접근성을 지키기 어려운 이유</h3>
						</div>

						<ul className="problem-list">
							<li>
								<span>🤔 쇼핑몰 운영자 A</span>
								<strong>웹 접근성? 그게 뭔데요? 처음 들어요.</strong>
							</li>
							<li>
								<span>🤔 마케터 B</span>
								<strong>콘텐츠마다 대체 텍스트를 일일이 타이핑? SEO 설정, SNS 썸네일 매번 반복작업?!</strong>
							</li>
							<li>
								<span>🤔 개발자 C</span>
								<strong>기능 개발하기도 빠듯한데.. 웹 접근성은 어렵고 오래 걸리잖아요. ㅠㅠ</strong>
							</li>
							<li>
								<span>😫 디자이너 D</span>
								<strong>웹 접근성 너무 복잡해요. 맞추다보면 어느새 디자인도 이상해지고.</strong>
							</li>
							<li>
								<span>😟 프로젝트매니저 E</span>
								<strong>웹 접근성 품질 인증마크 획득, 어디서부터 시작할지 막막해.</strong>
							</li>
							<li>
								<span>🤔 기획자 F</span>
								<strong>우리 웹 사이트가 모든 사용자에게 편리하게 사용될 수 있을까?</strong>
							</li>
						</ul>
					</div>
				</section>
				<section className="section section-problem">
					<div className="container-md">
						<div className="section-head">
							<h3 className="section-title">접근성을 지속 가능케 하려면?</h3>
							<h3 className="section-title mt-4">1. 쉬워야 합니다.</h3>
							<h3 className="section-title">2. 적은 노력으로 유지 가능해야 합니다.</h3>
							<h3 className="section-title">3. 모두가 함께 지켜야 합니다.</h3>
							<h3 className="section-title mt-4">우리의 관심과 노력이 필요합니다.</h3>
						</div>

						{/* 누가 알아주지도 않는데 뭐하러?
						법만 피하면 되는 거 아니야? */}
					</div>
				</section>
				<section className="section section-intro">
					<div className="container-md">
						<div className="section-head">
							<h3 className="section-title">
								🔮 웹 접근성 AI 마법사,
								<br className="d-down-sm" /> <span className="text-secondary">앨리(A11Y)</span>
							</h3>
						</div>
						<p className="text-xl text-medium mt-4">
							AI와 알고리즘 기술을 통해 <br className="d-down-md" /> 웹 접근성 콘텐츠 제작을 도와드려요.
						</p>
						<p className="text-md mt-2 mb-4">
							모두를 포용하는 웹 세상을 위해 사용자와 함께 <br className="d-down-md" />
							기술과 지식을 보다 쉽고, 재미있고, 편리하게!
							<br />웹 콘텐츠 제작의 업무 생산성에 도움을 주는
							<br className="d-down-md" /> 다양한 서비스를 제공하려고 해요.
						</p>
						<Link
							to="https://drive.google.com/file/d/1RZ-gccFYWeXKUJmfhdFcnCqaErg8gFIv/view?usp=sharing"
							target="_blank"
							title="새창"
							className="btn btn-xl btn-outline-white"
						>
							<span>서비스 소개서</span>
						</Link>
					</div>
				</section>
				<section className="section section-service">
					<div className="container-md">
						<div className="section-head">
							<h3 className="section-title">
								복잡하고 지루한
								<br className="d-down-sm" /> 작업은 AI로.
							</h3>
							<p className="section-desc">
								{/* 업무 생산성을 끌어올리는								<br className="d-down-sm" /> 앨리의 똑똑한 기능을 만나보세요 */}
								더이상의 핑계는 불허합니다.
							</p>
						</div>
						<div className="section-body">
							<ul className="service-overview">
								<li>
									<div className="subject">
										<img src={IconService1} alt="" className="icon" />
										<strong>이미지 HTML 변환기</strong>
										<span>대체텍스트를 HTML로 생성하고 편집해서 공유까지</span>
									</div>
									<img src={ImgService1} alt="이미지 HTML 변환기 예시 이미지" className="img-content" />
								</li>
								<li>
									<div className="subject">
										<img src={IconService2} alt="" className="icon" />
										<strong>이미지 명도대비 최적화</strong>
										<span>글자 색상 명도대비 진단과 개선을 자동으로</span>
									</div>
									<img src={ImgService2} alt="이미지 명도대비 최적화 예시 이미지" className="img-content" />
								</li>
								<li>
									<div className="subject">
										<img src={IconService3} alt="" className="icon" />
										<strong>SEO 메타태그 생성기</strong>
										<span>콘텐츠 제목, 키워드, 요약문 추출과 썸네일 생성</span>
									</div>
									<img src={ImgService3} alt="SEO 메타태그 생성기 예시 이미지" className="img-content" />
								</li>
								<li>
									<div className="subject">
										<img src={IconService4} alt="" className="icon" />
										<strong>표 접근성 최적화</strong>
										<span>표 접근성 위반 검사 및 접근성 지침 기반 캡션 생성</span>
									</div>
									<img src={ImgService4} alt="표 접근성 최적화 예시 이미지" className="img-content" />
								</li>
							</ul>
						</div>
					</div>
				</section>
				{/* <section className="section section-service">
					<div className="container-md">
						<div className="section-head">
							<h3 className="section-title">접근성 전문가 앨리와 함께하세요</h3>
							<p className="section-desc">접근성</p>
						</div>
						<div className="section-body">
							<div className="text-center">
								<p className="text-center">웹 접근성 전문가의 자동진단, 수동진단을 통한 보고서 제공 기타 등등</p>

								<Link className="mt-5 btn btn-outline-primary btn-xl">웹 접근성 전문가 지원하기</Link>
							</div>
						</div>
					</div>
				</section> */}

				<CTASection />

				<FooterLanding />
			</div>
			<PopupLogin isOpen={popupLoginCommon.isOpen} closePopup={popupLoginCommon.close} />
		</>
	);
}
