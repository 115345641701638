import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "@/components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHandleSeoCreate } from "@/services/core/seo/SeoCreateHandle";
import { refreshCancelTokenSource } from "@/services/util/Interceptor";

export default function SeoAddPage() {
	const data = useSelector((state) => state.seo.seoData.data);
	const [inputValue, setInputValue] = useState("");
	const [errorMessage, setErrorMessage] = useState(false);
	const seoData = useSelector((state) => state.seo.seoData);
	const data22 = useSelector((state) => state);

	const dispatch = useDispatch();

	// [개발] 화면 구현을 위한 임시 데이터 검사 내용입니다. => 수정 및 변경 필요

	const handleInputFocus = () => {
		setErrorMessage(false);
	};

	const handleInputBlur = (e) => {
		const url = e.target.value;
		setInputValue(url);
	};

	// URL 형식을 검증하는 함수
	const isValidUrl = (urlString) => {
		try {
			new URL(urlString);

			return true;
		} catch (error) {
			return false;
		}
	};

	// [개발] 화면 구현을 위한 상태값 확인 내용입니다. => 수정 및 변경 필요
	const [urlInputValue, setUrlInputValue] = useState("");
	const [textAreaValue, setTextAreaValue] = useState("");
	const { isLoading, handleSeoCheck } = useHandleSeoCreate(textAreaValue, urlInputValue);

	const location = useLocation();
	useEffect(() => {
		refreshCancelTokenSource();
		return () => {
			refreshCancelTokenSource();
		};
	}, [location]);

	const handleUrlInputChange = (e) => {
		const newUrlValue = e.target.value;
		setUrlInputValue(newUrlValue);

		if (newUrlValue.trim() === "" && textAreaValue.trim() === "") {
			setIsButtonDisabled(true);
			setErrorMessage(false);
		} else if (isValidUrl(newUrlValue)) {
			setErrorMessage(false);
			setIsButtonDisabled(false);
		} else {
			setErrorMessage(true);
			setIsButtonDisabled(textAreaValue.trim() === ""); // 텍스트 영역 값이 유효한지 확인
		}
	};

	const handleTextAreaChange = (e) => {
		const newTextValue = e.target.value;
		setTextAreaValue(newTextValue);

		// 텍스트 영역 값이 비어있지 않으면, URL 유효성 검사에 따라 버튼 활성화 상태 결정
		if (newTextValue.trim() === "" && urlInputValue.trim() === "") {
			setIsButtonDisabled(true);
		} else {
			setIsButtonDisabled(!isValidUrl(urlInputValue) && newTextValue.trim() === "");
		}
	};

	const isUrlInputDisabled = textAreaValue.trim() !== "";
	const isTextAreaDisabled = urlInputValue.trim() !== "";

	const [isButtonDisabled, setIsButtonDisabled] = useState(urlInputValue.trim() === "" && textAreaValue.trim() === "");

	return (
		<>
			<div className="container seowork-page">
				<div className="content-box content-tooladd">
					<div className="tooladd-wrap">
						<div className="tooladd-header">
							<h2 className="page-title">
								<i className="ico ico-service-seo-lg"></i>
								<span>SEO 메타태그 생성기</span>
							</h2>
						</div>
						<div className="tooladd-body">
							<div className="inner">
								<div className="box ">
									<div className="info-area">
										<strong>운용의 용이성 (6.4.2. 제목 제공)</strong>
										<ul className="list list1 mt-2">
											<li>웹 콘텐츠 제목을 적절하게 제공하면 시각장애인의 정보 탐색 시간을 크게 아껴줘요.</li>
											<li>
												검색엔진에 콘텐츠를 널리 노출하려면 메타태그를 검색엔진이 이해할 수 있는 형태로 제공해야 해요.
											</li>
											<li>
												앨리와 함께 웹 접근성을 고려한 제목, 키워드, 요약문, 섬네일, 메타태그를 쉽게 생성해보세요.
											</li>
										</ul>
									</div>
								</div>

								<p className="text-guide mt-8">
									블로그, 뉴스 등 의미를 가진 텍스트가 충분히 포함되어 있어야 정상적인 결과를 얻을 수 있어요.
								</p>

								{/* [개발] 입력값이 둘 중 하나가 들어가면 다른 필드는 비활성화 */}
								<form
									onSubmit={(e) => {
										e.preventDefault();
										handleSeoCheck();
									}}
								>
									<div className="input-wrap">
										<span className="input-link">
											<input
												type="url"
												id="inputurl"
												title="웹 콘텐츠 링크를 입력해주세요."
												className="form-control"
												placeholder="웹 콘텐츠 링크를 입력해주세요."
												onFocus={handleInputFocus}
												onBlur={handleInputBlur}
												onChange={handleUrlInputChange}
												value={urlInputValue}
												disabled={isUrlInputDisabled}
											/>
										</span>
										{errorMessage && (
											<p className="error-text">
												‘<span>{urlInputValue}</span>’의 IP 주소는 공인 주소가 아닙니다. 올바른 웹사이트 링크를
												입력해주세요.
											</p>
										)}
									</div>
									<span className="between-text">또는</span>

									<div className="input-wrap">
										<textarea
											rows="4"
											cols="50"
											className="form-control form-control-xxl"
											title="HTML 코드, 텍스트를 입력해주세요."
											placeholder="HTML 코드, 텍스트를 입력해주세요."
											onChange={handleTextAreaChange}
											value={textAreaValue}
											disabled={isTextAreaDisabled}
										/>
									</div>
								</form>
							</div>
							<div className="btn-group btn-group-flex mt-8">
								<Button
									type="button"
									id="checkSeo"
									icon={{ classes: "ico-tool-md" }}
									text="메타태그 AI 생성하기"
									loadingText={true}
									isLoading={isLoading}
									size="xl"
									color="primary"
									onClick={handleSeoCheck}
									disabled={isButtonDisabled}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
