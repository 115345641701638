import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Dropzone from "@/components/form/DragAndDrop";
import Button from "@/components/button/Button";
import { htmlCreate } from "@/services/core/Img2html/HtmlCreate";
import { createHtmlSuccess } from "@/services/store/action/Img2HtmlAction";
import { setUserData } from "@/services/store/action/LoginAction";
import { DataCount } from "@/services/core/mypage/MyDataCount";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { refreshCancelTokenSource } from "@/services/util/Interceptor";

const generateUniqueFileName = (fileType) => {
	const timestamp = new Date().toISOString().replace(/[-:]/g, "").split(".")[0]; // YYYYMMDDTHHmmss 형식
	const uniqueId = uuidv4().slice(0, 6); // UUID의 앞 6자리만 사용
	return `clipboard_${timestamp}_${uniqueId}.${fileType}`;
};

export default function Img2htmlAddPage() {
	const navigate = useNavigate();
	const [isFileUploaded, setIsFileUploaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isFile, setFile] = useState(null);
	const dispatch = useDispatch();

	const userData = useSelector((state) => state.user.userData);
	const allCount = userData?.data?.count || null;

	const handleBuyUsage = () => {
		setIsLoading(false);
		navigate("/setting/license");
	};

	const handleContrastCheck = async () => {
		if (allCount > 0) {
			window.confirm({
				title: "이미지를 HTML로 변환하시겠어요?",
				content: `사용권 1장이 차감됩니다.`,
				confirmButtonText: "변환",
				cancelButtonText: "취소",
				onConfirm: () => handleImg2HtmlCreate()
			});
		} else {
			window.confirm({
				title: "사용권이 없어요.",
				content: "새로운 사용권을 구매하거나 기간이 유효한 사용권코드를 입력해주세요.",
				confirmButtonText: "사용권 구매하기",
				cancelButtonText: "취소",
				onConfirm: () => handleBuyUsage()
			});
			setIsLoading(false);
		}
	};

	const handleImg2HtmlCreate = async () => {
		setIsLoading(true);

		if (!isFile || !isFile.name) {
			setIsLoading(false);
			alert({ message: "유효한 파일이 없습니다." });
			return;
		}

		const fileName = isFile.name;
		const fileType = isFile.type.split("/")[1] || "png";

		let fullName;
		if (fileName.startsWith("clipboard_")) {
			fullName = fileName;
		} else {
			const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf("."));
			const uniqueSuffix = `_${new Date().toISOString().replace(/[-:]/g, "").split(".")[0]}_${uuidv4().slice(0, 6)}`;
			fullName = `${nameWithoutExtension}${uniqueSuffix}.${fileType === "jpeg" ? "jpg" : fileType}`;
		}

		try {
			const resp = await htmlCreate(fullName, fileType, isFile);

			if (resp.message === "Success") {
				dispatch(createHtmlSuccess(resp));
				const updatedUserData = {
					...userData,
					data: {
						...userData.data,
						count: userData.data.count - 1
					}
				};
				dispatch(setUserData(updatedUserData));
				const dataCountResult = await DataCount();
				dispatch(setDataCount(dataCountResult));

				const Success = "Success";
				setIsLoading(false);
				const workId = resp.response.data.data.workspaceId;
				navigate(`/tool/img2html/work-detail/${encodeURIComponent(workId)}?extra=${encodeURIComponent(Success)}`);
			} else if (resp.message === "ERR_CANCELED") {
				setIsLoading(false);
			} else if (resp.message === "Fail") {
				setIsLoading(false);
				alert({ message: "잠시 후 다시 시도해주세요." });
			}
		} catch (error) {
			setIsLoading(false);
			alert({ message: "잠시 후 다시 시도해주세요." });
		}
	};

	const location = useLocation();
	useEffect(() => {
		refreshCancelTokenSource();
		return () => {
			refreshCancelTokenSource();
		};
	}, [location]);

	const onFileUploaded = useCallback((file) => {
		setFile(file);
		setIsFileUploaded(true);
	}, []);

	const onFileRemoved = useCallback(() => {
		setIsFileUploaded(false);
		setFile(null);
	}, []);

	const handlePaste = useCallback(
		(event) => {
			const items = event.clipboardData.items;
			for (let i = 0; i < items.length; i++) {
				if (items[i].type.indexOf("image") !== -1) {
					const blob = items[i].getAsFile();
					let fileExtension = "png"; // 기본값으로 'png' 사용

					// MIME 타입에 따라 확장자 결정
					if (blob.type === "image/jpeg") {
						fileExtension = "jpg";
					} else if (blob.type === "image/gif") {
						fileExtension = "gif";
					} else if (blob.type === "image/bmp") {
						fileExtension = "bmp";
					}

					const newFileName = generateUniqueFileName(fileExtension);
					const newFile = new File([blob], newFileName, { type: blob.type });
					onFileUploaded(newFile);
					event.preventDefault();
					break;
				}
			}
		},
		[onFileUploaded]
	);

	useEffect(() => {
		window.addEventListener("paste", handlePaste);
		return () => {
			window.removeEventListener("paste", handlePaste);
		};
	}, [handlePaste]);

	return (
		<>
			<div className="container img2html-page">
				<div className="content-box content-tooladd">
					<div className="tooladd-wrap">
						<div className="tooladd-header">
							<h2 className="page-title">
								<i className="ico ico-service-img2html-lg"></i>
								<span>이미지 HTML 변환기</span>
							</h2>
						</div>
						<div className="tooladd-body">
							<div className="inner">
								<div className="box ">
									<div className="info-area">
										<strong>인식의 용이성 (5.1.1. 적절한 대체 텍스트 제공)</strong>
										<ul className="list list1 mt-2">
											<li>시각장애인은 이미지에 글자로 된 설명문이 없으면 정보를 인식할 수 없어 큰 불편을 겪어요.</li>
											<li>
												대체 콘텐츠를 제공하면 화면 낭독기(스크린리더)를 통해 글자를 소리로 변환해 청각으로 이해할 수
												있어요.
											</li>
											<li>
												포스터, 카드뉴스, 배너, 쇼핑몰 상세이미지에 대체텍스트를 생성하고 웹 콘텐츠에 적용해보세요.
											</li>
										</ul>
									</div>
								</div>

								<p className="text-guide mt-8">이미지에 포함된 글자가 크고 선명할수록 더 좋은 결과를 얻을 수 있어요.</p>

								<div className="tool-file-upload">
									<Dropzone
										showDeleteButton={true}
										hideGuideText={!isFileUploaded}
										onFileUploaded={onFileUploaded}
										onFileRemoved={onFileRemoved}
										service={"IMG2"}
									/>
								</div>
							</div>
							<div className="btn-group btn-group-flex mt-8">
								<Button
									icon={{ classes: "ico-tool-md" }}
									text="변환하기"
									loadingText={true}
									isLoading={isLoading}
									size="xl"
									color="primary"
									onClick={handleContrastCheck}
									className={`btn btn-xl btn-primary ${!isFileUploaded ? "disabled" : ""}`}
									disabled={!isFileUploaded}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
